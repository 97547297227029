import React from "react"

import styles from "./messageSent.module.scss"

import MessageSentImage from "../images/message_sent.svg"

export default () => {
  return (
    <div className={styles.wrap}>
      <div className={styles.content}>
        <h1 className={styles.header}>...and sent!</h1>
        <img
          className={styles.image}
          src={MessageSentImage}
          alt="Message sent!"
        />
        <p className={styles.body}>
          Thanks for reaching out. We'll be in touch shortly.
        </p>
        <p className={styles.caption}>
          Sending you <a href="/">back to the home page</a> any second now.
        </p>
      </div>
    </div>
  )
}
