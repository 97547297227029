import React, { useEffect } from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import MessageSent from "../components/messageSent"
import { navigate } from "gatsby"

export default () => {
  useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 5000)
  })

  return (
    <Layout>
      <SEO title="Message Sent!" />
      <MessageSent />
    </Layout>
  )
}
